.forecast-menu-panel {
    background: #fff;
    position: absolute;
    top: 112px;
    right: 5px;
    width: 42px;
    height: 42px;
    z-index: 10002;
    max-width: 300px;
    font: 13px Arial, Helvetica, sans-serif;
    border-radius: 5px;
    border: 1px solid rgba(0,0,0,1);
    /*box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);*/
}

.forecast-menu-panel label{
    display: block;
    margin: 0px 0px 15px 0px;
}
.forecast-menu-panel label > span{
    width: 100px;
    font-weight: bold;
    float: left;
    padding-top: 8px;
    padding-right: 5px;
}

.forecast-menu-panel .tel-number-field{
    width: 40px;
    text-align: center;
}
.forecast-menu-panel input.input-field, .zoom-to-state-panel .select-field{
    width: 48%;
}
