.container {
    padding: 0px;
    flex: 1;
    background-color: #f7f7f7;
}

.header {
    padding-top: 0.5px;
    padding-bottom: 0.5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

}

.heading {
    font-size: 20px;
    margin-left: 20px;
    font-weight: 500;
    color: #126038;
}

.featured {
    width: 100%;
    display: flex;
    justify-content: space-between;

}

.featuredItem {
    flex: 1;
    margin: 40px 20px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 5px 10px rgba(154, 160, 185, 0.2), 0 15px 40px rgba(166, 173, 201, 0.1);
    background-color: white !important;

}

.feturedTop {
    width: 100%;
    display: flex;
    justify-content: space-between;

}

.featuredTitle {
    font-size: 18px;
    font-weight: 500;
}

.featuredContainer {
    margin: 10px 0px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid darkgrey;
}

.featuredNumber {
    font-size: 26px;
    font-weight: 500;
    padding-bottom: 10px;
}

.featuredSub {
    font-size: 12px;

}

.noTables {
    font-size: 30px;
    text-align: center;
    padding: 40px 0px;
}

.reportContainer {
    display: flex;
    flex: 1;
    flex-direction: row;
    margin-bottom: 110px;
}

.consumption {
    flex: 1;
    margin: 40px 20px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 5px 10px rgba(154, 160, 185, 0.2), 0 15px 40px rgba(166, 173, 201, 0.1);
    background-color: white !important;

}

.consumptionTitle {
    font-size: 20px;
}

.showContainer {
    margin-top: 20px;

}

.jediniceContainer {
    margin-top: 20px;
    margin-right: 20px;
}

.jediniceItem {

    font-size: 20px;
    font-weight: 500;
    padding: 20px;
    margin: 0px 80px;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    text-align: center;
}