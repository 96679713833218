.menu-item,
.menu-open-button {
   background: #EEEEEE;
   border-radius: 100%;
   width: 60px;
   height: 60px;
   margin-left: -40px;
   position: absolute;
   color: #FFFFFF;
   text-align: center;
   line-height: 60px;
   -webkit-transform: translate3d(0, 0, 0);
   transform: translate3d(0, 0, 0);
   -webkit-transition: -webkit-transform ease-out 200ms;
   transition: -webkit-transform ease-out 200ms;
   transition: transform ease-out 200ms;
   transition: transform ease-out 200ms, -webkit-transform ease-out 200ms;
   cursor: pointer;
}

.menu-open {
   display: none;
}

.lines {
   width: 25px;
   height: 3px;
   background: #ffffff;
   display: block;
   position: absolute;
   top: 50%;
   left: 50%;
   margin-left: -12.5px;
   margin-top: -1.5px;
   -webkit-transition: -webkit-transform 200ms;
   transition: -webkit-transform 200ms;
   transition: transform 200ms;
   transition: transform 200ms, -webkit-transform 200ms;
}

.line-1 {
   -webkit-transform: translate3d(0, -8px, 0);
   transform: translate3d(0, -8px, 0);
}

.line-2 {
   -webkit-transform: translate3d(0, 0, 0);
   transform: translate3d(0, 0, 0);
}

.line-3 {
   -webkit-transform: translate3d(0, 8px, 0);
   transform: translate3d(0, 8px, 0);
}

.menu-open:checked+.menu-open-button .line-1 {
   -webkit-transform: translate3d(0, 0, 0) rotate(45deg);
   transform: translate3d(0, 0, 0) rotate(45deg);
}

.menu-open:checked+.menu-open-button .line-2 {
   -webkit-transform: translate3d(0, 0, 0) scale(0.1, 1);
   transform: translate3d(0, 0, 0) scale(0.1, 1);
}

.menu-open:checked+.menu-open-button .line-3 {
   -webkit-transform: translate3d(0, 0, 0) rotate(-45deg);
   transform: translate3d(0, 0, 0) rotate(-45deg);
}

.menu {
   margin: auto;
   position: absolute;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   width: 60px;
   height: 60px;
   text-align: center;
   box-sizing: border-box;
   font-size: 26px;
   padding-left: 20px;
}


/* .menu-item {
   transition: all 0.1s ease 0s;
} */

.menu-item:hover {
   /* background: #acacac; */
   box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.3), 2px 2px 5px 0px rgb(0 0 0 / 20%);

   color: #3290B1;
}

.menu-item:nth-child(3) {
   -webkit-transition-duration: 180ms;
   transition-duration: 180ms;
}

.menu-item:nth-child(4) {
   -webkit-transition-duration: 180ms;
   transition-duration: 180ms;
}

.menu-item:nth-child(5) {
   -webkit-transition-duration: 180ms;
   transition-duration: 180ms;
}

.menu-item:nth-child(6) {
   -webkit-transition-duration: 180ms;
   transition-duration: 180ms;
}

.menu-item:nth-child(7) {
   -webkit-transition-duration: 180ms;
   transition-duration: 180ms;
}

.menu-item:nth-child(8) {
   -webkit-transition-duration: 180ms;
   transition-duration: 180ms;
}

.menu-item:nth-child(9) {
   -webkit-transition-duration: 180ms;
   transition-duration: 180ms;
}

.menu-open-button {
   z-index: 2;
   -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
   transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
   -webkit-transition-duration: 400ms;
   transition-duration: 400ms;
   -webkit-transform: scale(1.1, 1.1) translate3d(0, 0, 0);
   transform: scale(1.1, 1.1) translate3d(0, 0, 0);
   cursor: pointer;
   box-shadow: 2px 2px 5px 0px rgb(0 0 0 / 20%);
}

.menu-open-button:hover {
   -webkit-transform: scale(1.15, 1.15) translate3d(0, 0, 0);
   transform: scale(1.15, 1.15) translate3d(0, 0, 0);
   box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.3), 2px 2px 5px 0px rgb(0 0 0 / 20%);

}

.menu-open:checked+.menu-open-button {
   -webkit-transition-timing-function: linear;
   transition-timing-function: linear;
   -webkit-transition-duration: 200ms;
   transition-duration: 200ms;
   -webkit-transform: scale(0.8, 0.8) translate3d(0, 0, 0);
   transform: scale(0.8, 0.8) translate3d(0, 0, 0);
}

.menu-open:checked~.menu-item {
   -webkit-transition-timing-function: cubic-bezier(0.935, 0, 0.34, 1.33);
   transition-timing-function: cubic-bezier(0.935, 0, 0.34, 1.33);
}

.menu-open:checked~.menu-item:nth-child(3) {
   transition-duration: 180ms;
   -webkit-transition-duration: 180ms;
   -webkit-transform: translate3d(0px, -74px, 0);
   transform: translate3d(0px, -74px, 0);
}

.menu-open:checked~.menu-item:nth-child(4) {
   transition-duration: 280ms;
   -webkit-transition-duration: 280ms;
   -webkit-transform: translate3d(72px, -22px, 0);
   transform: translate3d(72px, -22px, 0);
}

.menu-open:checked~.menu-item:nth-child(5) {
   transition-duration: 380ms;
   -webkit-transition-duration: 380ms;
   -webkit-transform: translate3d(46px, 63px, 0);
   transform: translate3d(46px, 63px, 0);
}

.menu-open:checked~.menu-item:nth-child(6) {
   transition-duration: 480ms;
   -webkit-transition-duration: 480ms;
   -webkit-transform: translate3d(0px, 104px, 0);
   transform: translate3d(0px, 104px, 0);
}

.menu-open:checked~.menu-item:nth-child(7) {
   transition-duration: 580ms;
   -webkit-transition-duration: 580ms;
   -webkit-transform: translate3d(-46px, 63px, 0);
   transform: translate3d(-46px, 63px, 0);
}

.menu-open:checked~.menu-item:nth-child(8) {
   transition-duration: 680ms;
   -webkit-transition-duration: 680ms;
   -webkit-transform: translate3d(-72px, -22px, 0);
   transform: translate3d(-72px, -22px, 0);
}

.menu-open:checked~.menu-item:nth-child(9) {
   transition-duration: 780ms;
   -webkit-transition-duration: 780ms;
   -webkit-transform: translate3d(-0.25084px, -104.9997px, 0);
   transform: translate3d(-0.25084px, -104.9997px, 0);
}

.open {
   background-color: #058457;
   box-shadow: 2px 2px 5px 0px rgb(0 0 0 / 20%);
   text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
}

.open:hover {
   color: #058457;
   text-shadow: none;
}

.progress {
   background-color: #f3e741;
   box-shadow: 2px 2px 5px 0px rgb(0 0 0 / 20%);
   text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
}

.progress:hover {
   color: #f3e741;
   text-shadow: none;
}

.finished {
   background-color: #73db4d;
   box-shadow: 2px 2px 5px 0px rgb(0 0 0 / 20%);
   text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
}

.finished:hover {
   color: #73db4d;
   text-shadow: none;
}

.purple {
   background-color: #C49CDE;
   box-shadow: 2px 2px 5px 0px rgb(0 0 0 / 20%);
   text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
}

.purple:hover {
   color: #C49CDE;
   text-shadow: none;
}

.locked {
   background-color: #e40f0f;
   box-shadow: 2px 2px 5px 0px rgb(0 0 0 / 20%);
   text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
}

.locked:hover {
   color: #e40f0f;
   text-shadow: none;
}

.planned {
   background-color: #2e63c5;
   box-shadow: 2px 2px 5px 0px rgb(0 0 0 / 20%);
   text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
}

.planned:hover {
   color: #2e63c5;
   text-shadow: none;
}

.icon_filter {
   filter: invert(100%) sepia(100%) saturate(100%) hue-rotate(180deg) brightness(100%) contrast(100%);
   user-select: none;
}

.tooltip_open {
   position: absolute;
   bottom: 50px;
   color: black;
   font-size: 16px;
   width: max-content;
   text-align: center;
   opacity: 0;
}

.tooltip_open_hover {
   position: absolute;
   bottom: 50px;
   color: black;
   font-size: 16px;
   width: max-content;
   text-align: center;
   opacity: 1;
}

.tooltip_progress {
   position: absolute;
   bottom: 0px;
   color: black;
   font-size: 16px;
   width: max-content;
   text-align: center;
   opacity: 0;
   left: 74px;
}

.tooltip_progress_hover {
   position: absolute;
   bottom: 0px;
   color: black;
   font-size: 16px;
   left: 74px;
   width: max-content;
   text-align: center;
   opacity: 1;
}

.tooltip_finished {
   position: absolute;
   bottom: 0px;
   color: black;
   font-size: 16px;
   width: max-content;
   text-align: center;
   opacity: 0;
   left: 74px;
}

.tooltip_finished_hover {
   position: absolute;
   bottom: 0px;
   color: black;
   font-size: 16px;
   left: 74px;
   width: max-content;
   text-align: center;
   opacity: 1;
}

.tooltip_locked {
   position: absolute;
   bottom: 0px;
   color: black;
   font-size: 16px;
   width: max-content;
   text-align: center;
   opacity: 0;
   right: 74px;
}

.tooltip_locked_hover {
   position: absolute;
   bottom: 0px;
   color: black;
   font-size: 16px;
   right: 74px;
   width: max-content;
   text-align: center;
   opacity: 1;
}

.tooltip_planned {
   position: absolute;
   bottom: 0px;
   color: black;
   font-size: 16px;
   width: max-content;
   text-align: center;
   opacity: 0;
   right: 70px;
}

.tooltip_planned_hover {
   position: absolute;
   bottom: 0px;
   color: black;
   font-size: 16px;
   right: 70px;
   width: max-content;
   text-align: center;
   opacity: 1;
}