.popup-parcel .leaflet-popup-content-wrapper{
    background-color: #d6e2ea;
    opacity: 0.9;
}
.popup-parcel .leaflet-popup-tip {
    opacity: 0;
}

.popup-parcel-name {
    font-size: 24px;
    font-weight: bold;
    margin-top: 10px;
}

.popup-parcel-subtitle {
    font-size: 18px;
    margin-top: 10px;
}

.popup-parcel-attributes {
    font-size: 14px;
    font-style: italic;
}

.popup-sensor .leaflet-popup-content-wrapper{
    background-color: #ffffff;
    opacity: 0.9;
}

.popup-sensor .leaflet-popup-content{
    margin-top: 0px;
    margin-bottom: 0px;
}

.popup-sensor .leaflet-popup-tip {
    opacity: 0;
}

.popup-sensor-name {
    font-size: 24px;
    font-weight: bold;
    margin-top: 10px;
}

.popup-sensor-subtitle {
    font-size: 18px;
    margin-top: 10px;
}

.popup-sensor-attributes {
    font-size: 14px;
    font-style: italic;
}

img.leaflet-marker-icon { 
    filter: drop-shadow(1px 1px 1px #474747);
    /* opacity: 0.9; */
}